<template>
  <v-container
    v-if="company"
    fluid
    fill-height
  >
    <v-card
      class="rounded-t-xs rounded-b-xs mt-n1"
      style="width: 100%; height: 100%;"
    >
      <div class="mt-1">
        <v-tabs
          v-model="selectedTab"
          active-class="white"
          slider-color="accent"
          grow
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.index"
          >
            <div>
              <div style="font-size:10px;">
                {{ tab.title }}
              </div>
              <div>
                {{ tab.text }}
              </div>
            </div>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <MembersTable />
          </v-tab-item>
          <v-tab-item>
            <CompanyProfile />
          </v-tab-item>
          <v-tab-item>
            <CompanySettings />
          </v-tab-item>
          <v-tab-item>
            <GroupMembersTable />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import MembersTable from './_membersTable.vue'
import { mapState } from 'vuex'
import CompanyProfile from './CompanyProfile.vue'
import CompanySettings from './ComapnySettings.vue'
import GroupMembersTable from './GroupMembersTable.vue'

export default {
  components: {
    MembersTable,
    CompanyProfile,
    CompanySettings,
    GroupMembersTable
  },
  data: () => ({
    selectedTab: 0,
    dialog: false
  }),
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    tabs () {
      if (!this.company) return []
      return [
        {
          title: this.company.companyData.companyName,
          text: this.$t('members|members'),
          index: 0
        },
        {
          title: this.company.companyData.companyName,
          text: this.$t('profile|profile'),
          index: 1
        },
        {
          title: this.company.companyData.companyName,
          text: this.$t('settings|settings'),
          index: 2
        },
        {
          title: this.company.companyData.companyName,
          text: this.$t('groups|groups'),
          index: 3
        }
        // {
        //   text: 'Billing',
        //   index: 3
        // }
      ]
    }
  },
  methods: {
    close () {
      this.$refs.addMember.close()
      this.dialog = false
    }
  }
}
</script>
